import {
    Box,
    Button,
    createStyles,
    Dialog,
    IconButton,
    Theme,
    useMediaQuery,
    useTheme,
    withStyles,
} from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import magula from "react-syntax-highlighter/dist/esm/styles/hljs/magula";
import imgCompanyLogo from "../../assets/images/tutorial/logo.png";
import imgVersion from "../../assets/images/tutorial/version.png";
import imgDownload from "../../assets/images/tutorial/download.png";
import imgViewer from "../../assets/images/tutorial/viewer.png";
import imgConfiguration from "../../assets/images/tutorial/configuration.png";
import config from "../../config/config";
import { useProjectId, useUrn } from "../../redux/viewer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        title: {
            fontSize: "1.8rem",
            lineHeight: "1.8rem",
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        mt1: {
            marginTop: theme.spacing(1),
        },
        mt2: {
            marginTop: theme.spacing(2),
        },
        image: {
            width: "100%",
            padding: theme.spacing(1),
            textAlign: "center",
            "& > img": {
                width: "90%",
                height: "auto",
                margin: "auto",
            },
        },
    }),
);

export interface DialogTitleProps {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;
    const classes = useStyles();
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h4" component="h2" className={classes.title}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

interface IProps {
    children?: any;
    open: boolean;
    handleClose: () => void;
}

const Tutorial: React.FC<IProps> = props => {
    const { open, handleClose } = props;
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const projectId = useProjectId();
    const urn = useUrn();

    const codeCss = `<body>\n    ...\n    <link rel="stylesheet" href="iolabs-viewer.2.0.0.min.css">\n    ...\n</body>`;

    const codeJs = `<head>\n    ...\n    <script src="iolabs-viewer.2.0.0.min.js"></script>\n</head>`;

    const codeViewer = `...\n    <div id="iolabs__viewer"></div>\n...\n`;

    const codeConfiguration = `iolabs.viewer.init({
        projectId: "PROJECT_ID",
        urn: "URN",
        latest: true,
        divId: "iolabs__viewer"
    });`;

    const codeConfigurationWithViewable = `iolabs.viewer.init({
        projectId: 'PROJECT_ID',
        urn: 'URN',
        preferredViewableName: "Example view name",
        latest: true,
        divId: 'iolabs__viewer'
    }).then(function(viewer) {
        viewer.addEventListener(Autodesk.Viewing.VIEWER_INITIALIZED, function() {
            console.log("Viewer initialized");
        });
    });`;

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="tutorial-dialog-title"
                open={open}
                maxWidth="lg"
                fullScreen={fullScreen}
                scroll="paper"
            >
                <DialogTitle id="tutorial-dialog-title" onClose={handleClose}>
                    Tutorial
                </DialogTitle>
                <DialogContent dividers>
                    <Typography variant="h6" component="h3" gutterBottom>
                        Input part:
                    </Typography>

                    <Typography gutterBottom className={classes.mt2}>
                        <strong>1) Embeddable version</strong>
                    </Typography>
                    <Typography gutterBottom>
                        Choose which version of the currently displayed model you want.
                    </Typography>
                    <Typography gutterBottom>
                        <strong>Current</strong> or <strong>latest</strong> version.
                    </Typography>
                    <Box className={classes.image}>
                        <img src={imgVersion} alt="Embeddable latest version" />
                    </Box>

                    <Typography gutterBottom className={classes.mt2}>
                        <strong>2) Company logo URL</strong>
                    </Typography>
                    <Typography gutterBottom>
                        If you want you can insert your own logo by a URL link to an image.
                    </Typography>
                    <Box className={classes.image}>
                        <img src={imgCompanyLogo} alt="Company logo URL" />
                    </Box>

                    <Typography variant="h6" component="h3" gutterBottom className={classes.mt2}>
                        Output part:
                    </Typography>

                    <Typography gutterBottom className={classes.mt2}>
                        <strong>1) Preview</strong>
                    </Typography>
                    <Typography gutterBottom>
                        For a complete preview of integration, see the test sample page.
                    </Typography>
                    <Box mt={1} mb={1}>
                        <Button
                            variant="contained"
                            color="primary"
                            href={`${config.content.liveDemoUrl}?projectId=${projectId}&urn=${urn}`}
                            target="_blank"
                        >
                            Visit sample page
                        </Button>
                    </Box>

                    <Typography gutterBottom className={classes.mt2}>
                        <strong>2) Download ZIP archive</strong>
                    </Typography>
                    <Typography gutterBottom>
                        Use <strong>Download ZIP archive</strong> button. ZIP archive contains CSS and JS files, so
                        extract and use them into your project. Include <i>iolabs-viewer.min.css</i> file to the head
                        part of your HTML page.
                    </Typography>
                    <SyntaxHighlighter language="html" style={magula}>
                        {codeCss}
                    </SyntaxHighlighter>
                    <Typography gutterBottom>
                        Include <i>iolabs-viewer.min.js</i> to the place where the other scripts were linked. Especially
                        in the end of the body part of your HTML page.
                    </Typography>
                    <SyntaxHighlighter language="html" style={magula}>
                        {codeJs}
                    </SyntaxHighlighter>
                    <Box className={classes.image}>
                        <img src={imgDownload} alt="Download ZIP archive" />
                    </Box>

                    <Typography gutterBottom className={classes.mt2}>
                        <strong>3) Viewer element</strong>
                    </Typography>
                    <Typography gutterBottom>
                        Use <strong>Copy code to clipboard </strong>button and the following code will be copied to your
                        clipboard. Place viewer element inside the body part of your HTML. The viewer will be rendered
                        here.
                    </Typography>
                    <SyntaxHighlighter language="html" style={magula}>
                        {codeViewer}
                    </SyntaxHighlighter>
                    <Box className={classes.image}>
                        <img src={imgViewer} alt="Viewer element" />
                    </Box>

                    <Typography gutterBottom className={classes.mt2}>
                        <strong>4) Script configuration and activation</strong>
                    </Typography>
                    <Typography gutterBottom>
                        Use <strong>Copy code to clipboard</strong> button and the following code will be copied to your
                        clipboard. Paste the copied code into your own script to activate it using <i>init()</i>{" "}
                        function.
                    </Typography>
                    <SyntaxHighlighter language="javascript" style={magula}>
                        {codeConfiguration}
                    </SyntaxHighlighter>
                    <Box className={classes.image}>
                        <img src={imgConfiguration} alt="Script configuration and activation" />
                    </Box>

                    <Typography gutterBottom className={classes.mt2}>
                        <strong>5) Setting a specific viewable and handling the viewer</strong>
                    </Typography>
                    <Typography gutterBottom>
                        You can also set a specific viewable by name in the <i>init</i> configuration. The <i>init</i>{" "}
                        function returns a promise with the viewer, which you can use according to the Forge viewer
                        documentation.
                    </Typography>
                    <SyntaxHighlighter language="javascript" style={magula}>
                        {codeConfigurationWithViewable}
                    </SyntaxHighlighter>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} variant="contained" color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Tutorial;
